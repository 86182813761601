import React from "react";
import type { NextPage } from "next";
import { theme } from "@pagepro-monorepo/ui";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import { Inner } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import Section from "@components/layout/Section";
import Spacer from "@components/layout/Spacer";
import ButtonLink from "@components/atoms/ButtonLink";
import baseGetStaticProps from "@utils/baseGetStaticProps";
import { getSpacings } from "@utils/getSpacings";

import * as Styled from "./styles";
import { spacings } from "./consts";

const NotFound: NextPage = () => {
  const { topSpacing, bottomSpacing } = getSpacings(...spacings);

  return (
    <Styled.Wrapper>
      <Section
        color={theme.colors.white}
        pt={topSpacing}
        pb={bottomSpacing}
      >
        <Inner>
          <Typography variant="heading1" as="h1">
            404
          </Typography>
          <Typography variant="heading2" as="h1">
            Page not found
          </Typography>
          <Spacer pt={["1rem", "2rem", "3rem"]} />
          <ButtonLink $mode="secondary" href="/">
            Homepage
          </ButtonLink>
        </Inner>
      </Section>
    </Styled.Wrapper>
  );
};

export default NotFound;

//////////////////////
///////////////////////////////////////////////////////////////
///////////
////
//////////
