import styled, { css } from "styled-components";
import { space, color, compose } from "styled-system";
import {
  FlexBox,
  Inner as InnerComponent
} from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import { ButtonLink } from "@pagepro-monorepo/ui/lib/components/atoms/Button";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import shouldForwardProp from "@pagepro-monorepo/ui/lib/utils/shouldForwardProp";

import { IconArrowDown } from "@assets/svg";

import { StyledSectionProps, StyledSectionInnerProps } from "./types";

export const Wrapper = styled("section").withConfig({
  shouldForwardProp
})<StyledSectionProps>`
  ${compose(space, color)};
  position: relative;
  width: 100%;
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

export const Inner = styled(InnerComponent)<StyledSectionInnerProps>`
  ${({ isRelative }) =>
    isRelative &&
    css`
      position: relative;
    `}
`;

export const ScrollToWrapper = styled(FlexBox)`
  display: none;
  @media ${media.mobile} {
    display: flex;
    position: absolute;
    right: 1.125rem;
    bottom: 3.25rem;
    z-index: 1;
  }
  @media ${media.tablet} {
    top: 100%;
    right: 2rem;
    bottom: auto;
    transform: translateY(-50%);
  }
`;

export const ScrollTo = styled(ButtonLink)`
  box-shadow: 0 0.3125rem 1.25rem rgba(0, 0, 0, 0.15);
`;

export const ScrollToIcon = styled(IconArrowDown)`
  height: 1em;
  width: 0.8em;
  font-size: 1.3125rem;
  fill: ${({ theme }) => theme.colors.white};
  @media ${media.tablet} {
    font-size: 1.75rem;
  }
`;

export const Anchor = styled.div`
  display: block;
  position: absolute;
  top: ${({ theme }) => `-${theme.sizes.header}`};
  visibility: hidden;
`;
